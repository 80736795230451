const services = [
  {
    icon: new URL(
      '../../public/images/services/building-website.svg',
      import.meta.url,
    ),
    alt: "construction d'un site",
    title: 'Projet Web',
    desc: 'Landing page, site vitrine, e-commerce',
    id: 0,
  },
  {
    icon: new URL(
      '../../public/images/services/concept-of-seo-ranking.svg',
      import.meta.url,
    ),
    alt: 'image SEO',
    title: 'Réferencement naturel',
    desc: 'Affichage sémantique des informations, des pages propres pour un référencement optimal.',
    id: 1,
  },
  {
    icon: new URL(
      '../../public/images/services/collaborate.svg',
      import.meta.url,
    ),
    alt: 'collaboration',
    title: 'Développement web',
    desc: 'Création de site web | Application sur-mesure repondant aux besoins des clients',
    id: 2,
  },
  {
    icon: new URL(
      '../../public/images/services/website-designing.svg',
      import.meta.url,
    ),
    alt: "design d'un site",
    title: 'UI/UX design',
    desc: 'Logos, templates Web, plaquettes publicitaires, cartes de visite, newsletters...',
    id: 3,
  },
  {
    icon: new URL(
      '../../public/images/services/facebook-advertisement-tutorial.svg',
      import.meta.url,
    ),
    alt: 'publicité Facebook',
    title: 'Marketing Facebook',
    desc: 'Mise en place de publicités afin de promouvoir votre marque, votre association',
    id: 4,
  },
  {
    icon: new URL(
      '../../public/images/services/website-maintenance.svg',
      import.meta.url,
    ),
    alt: "maintenance d'un site",
    title: 'Maintenance Web',
    desc: "Gestion d'hébérgement, garanti d'accessibilité, intégration régulière des mises à jour de sécurité",
    id: 5,
  },
];

export default services;
