import React from 'react';
import Layout from '../../components/layout/LayoutContainer';
import { SectionTitle } from '../../styles/GlobalComponents';
import { Main } from './ProcessPageStyles';

function ProcessPage() {
  return (
    <Layout>
      <Main>
        <SectionTitle>En cours de développement ...</SectionTitle>
      </Main>
    </Layout>
  );
}

export default ProcessPage;
